import { inject, observer } from 'mobx-react';
import React from 'react';

import { LoginRequest } from '@headway/api/models/LoginRequest';
import { SignupRequest } from '@headway/api/models/SignupRequest';
import { UserRead } from '@headway/api/models/UserRead';

export interface IAuthStore {
  closeForgotPasswordModal: () => void;
  closeLoginModal: () => void;
  closeResetPasswordModal: () => void;
  closeSignupModal: () => void;
  forgotPasswordModalOpen: boolean;
  gatedRedirectLocation?: string;
  inviteToken?: string;
  isUserRegistered: () => boolean;
  logIn: (loginRequest: LoginRequest) => Promise<UserRead>;
  loginModalOpen: boolean;
  logout: ({ useAuth0 }: { useAuth0: boolean }) => void;
  openForgotPasswordModal: () => void;
  openLoginModal: () => void;
  openResetPasswordModal: () => void;
  openSignupModal: () => void;
  redirectToAuth0Login: () => void;
  guardedRedirectToAuth0Login: ({
    useAuth0,
    postLogInRedirectTo,
  }: {
    useAuth0: boolean;
    postLogInRedirectTo?: string;
  }) => void;
  resetPasswordModalOpen: boolean;
  resetPasswordToken?: string;
  setUser: (user: UserRead) => void;
  signUp: (signupRequest: SignupRequest) => Promise<UserRead>;
  signupModalOpen: boolean;
  user: UserRead;
}

export interface IUiStore {
  alertButtonColor: string;
  alertButtonOnClick: () => void;
  alertButtonText: string;
  alertCbOnClose: () => void;
  alertMessage: string;
  alertMessageTitle: string;
  alertMessageType: string;
  canShowInsuranceHelperModal: (frontEndCarrierId: number) => boolean;
  isLoading: boolean;
  showAlert: boolean;
  showInsuranceHelperModal: (frontEndCarrierId: number) => void;
  showSuccessAlert: (
    title: string,
    message: string | JSX.Element,
    alertCbOnClose?: () => void,
    alertButtonText?: string,
    alertButtonColor?: string,
    alertButtonOnClick?: () => void
  ) => void;
  showWarningAlert: (
    title: string,
    message: string | JSX.Element,
    alertCbOnClose?: () => void,
    alertButtonText?: string,
    alertButtonColor?: string,
    alertButtonOnClick?: () => void
  ) => void;
  providerId?: number;
  getProviderId: (id: number) => void;
}

export interface WithStoresChildProps {
  AuthStore: IAuthStore;
  UiStore: IUiStore;
}

export function withStores<
  T extends WithStoresChildProps = WithStoresChildProps,
>(Component: React.ComponentType<T>) {
  const WithStores = inject(
    'AuthStore',
    'UiStore'
  )(
    observer(
      class WithStores extends React.Component<
        Omit<T, keyof WithStoresChildProps>
      > {
        render() {
          const { AuthStore, UiStore, ...rest } = this.props as T;
          if (AuthStore && UiStore) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { ..._unusedMobXForceRerenderAuthStore } = AuthStore; // have to do this to force mobx to re-render this component
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { ..._unusedMobXForceRerenderUiStore } = UiStore; // have to do this to force mobx to re-render this component
            return (
              <Component
                {...(rest as T)}
                AuthStore={AuthStore}
                UiStore={UiStore}
              />
            );
          }

          return null;
        }
      }
    )
  );

  return WithStores;
}
