import { v4 as uuidv4 } from 'uuid';

import { UserRead } from '@headway/api/models/UserRead';
import { buildContext } from '@headway/feature-flags/context/builder';
import type { BuildContextInput } from '@headway/feature-flags/context/builder';

const appMetadata: BuildContextInput['appMetadata'] = { name: 'AGORA' };

const buildLaunchDarklyContextFromUser = (
  requestUser?: UserRead
): ReturnType<typeof buildContext> => {
  let user: BuildContextInput['user'];

  if (!requestUser) {
    user = {
      userType: 'unidentified',
    };
  } else {
    user = {
      userType: 'identified',
      id: requestUser.id.toString(),
      email: requestUser.email,
    };
  }

  // eslint-disable-next-line no-restricted-syntax -- This is one of the adapters the lint rule is referring to, we need to call buildContext here.
  return buildContext({ user, appMetadata });
};

const buildLaunchDarklyContextFromRequest = (req: {
  isBot?: boolean;
  userMeData?: UserRead;
  session?: { id: typeof uuidv4 } | null;
}): ReturnType<typeof buildContext> => {
  let user: BuildContextInput['user'];

  if (req.isBot) {
    user = {
      userType: 'bot',
    };
  } else if (!req.userMeData) {
    user = {
      userType: 'unidentified',
    };
  } else {
    user = {
      userType: 'identified',
      id: req.userMeData.id.toString(),
      email: req.userMeData.email,
    };
  }

  let sessionId: string | undefined = undefined;
  if (req.session) {
    sessionId = req.session.id.toString();
  }

  // eslint-disable-next-line no-restricted-syntax -- This is one of the adapters the lint rule is referring to, we need to call buildContext here.
  return buildContext({
    user,
    appMetadata,
    sessionId,
  });
};

export {
  buildLaunchDarklyContextFromUser,
  buildLaunchDarklyContextFromRequest,
};
