import type { HeadwayAppName } from './constants';
import { LegacyUserContext } from './kinds';

type BotUser = {
  userType: 'bot';
};

type UnIdentifiedUser = {
  userType: 'unidentified';
};

type IdentifiedUser = {
  userType: 'identified';
  id: string;
  email?: string;
};

type BuildContextInput = {
  user: BotUser | UnIdentifiedUser | IdentifiedUser;
  sessionId?: string;
  appMetadata: { name: HeadwayAppName };
};

/**
 * Unified LaunchDarkly context builder for use in external @headway/feature-flags functions (e.g. getFlag, useFlag, identify).
 *
 * @param user - an object telling us what kind of user we've identified and relevant associated user details.
 * @param appMetadata - an object containing the calling app name.
 * @param sessionId - an optional session ID that's set by the cookie-session middleware in our SSR apps.
 * @returns A legacy launchdarkly user context object.
 */
const buildContext = ({
  user,
  sessionId,
  appMetadata,
}: BuildContextInput): LegacyUserContext => {
  let userContext: LegacyUserContext;

  if (user.userType === 'bot') {
    userContext = {
      kind: 'user',
      key: 'bot',
      anonymous: true,
      HEADWAY_APP: appMetadata.name,
    };
  } else if (user.userType === 'unidentified') {
    // We are setting the key to `bot` for backwards compatibility with @headway/shared/FeatureFlags as unidentified and bot users were originally treated the same.
    userContext = {
      kind: 'user',
      key: 'bot',
      anonymous: true,
      HEADWAY_APP: appMetadata.name,
    };
  } else {
    userContext = {
      kind: 'user',
      key: user.id,
      email: user.email,
      visitorId: user.id,
      HEADWAY_APP: appMetadata.name,
    };
  }

  if (sessionId) {
    userContext.sessionId = sessionId;
  }

  return userContext;
};

export type { BuildContextInput };
export { buildContext };
