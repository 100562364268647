import clsx from 'clsx';
import NextImage, {
  ImageProps as NextImageProps,
  StaticImageData,
} from 'next/legacy/image';
import React from 'react';

import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';

export interface ImageProps {
  src: StaticImageData;
  alt: string;
  sizes?: NextImageProps['sizes'];
  className?: string;
}

export const Image: React.FC<ImageProps> = ({
  src,
  alt,
  sizes,
  className,
  ...rest
}) => {
  return (
    <div className={clsx('overflow-hidden', className ?? 'w-full')} {...rest}>
      <NextImage
        {...(process.env.NEXT_PUBLIC_IMAGE_LOADER_TYPE === 'cloudflare'
          ? {
              loader: transformCloudflareImg,
            }
          : {})}
        src={src}
        alt={alt}
        layout="responsive"
        quality={100}
        sizes={sizes}
      />
    </div>
  );
};

export type { StaticImageData };
