import { UserRead } from '@headway/api/models/UserRead';

export enum UserVerificationStatus {
  REGISTERED_AND_VERIFIED = 'REGISTERED_AND_VERIFIED',
  REGISTERED_NOT_VERIFIED = 'REGISTERED_NOT_VERIFIED',
  UNREGISTERED = 'UNREGISTERED',
}

export const getVerificationStatus = (
  user: UserRead
): UserVerificationStatus => {
  if (isUserRegistered(user) && isUserVerified(user)) {
    return UserVerificationStatus.REGISTERED_AND_VERIFIED;
  }

  if (isUserRegistered(user) && !isUserVerified(user)) {
    return UserVerificationStatus.REGISTERED_NOT_VERIFIED;
  }

  return UserVerificationStatus.UNREGISTERED;
};

export const isUserRegistered = (
  user?: UserRead
): user is Omit<UserRead, 'email'> & { email: string } => {
  return !!user?.email;
};

const isUserVerified = (user?: UserRead): boolean => {
  return !!user?.isVerified;
};
