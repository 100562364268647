import type { Request } from 'express';
// eslint-disable-next-line no-restricted-imports
import type { NextRouter } from 'next/router';
import { stringify as stringifyParams } from 'query-string';

type Url = Parameters<NextRouter['replace']>[0];

export function normalizeUrl(url: Url): string {
  if (typeof url === 'string') {
    return url;
  }

  let search = url.search;
  if (!search && url.query) {
    if (typeof url.query === 'string') {
      search = url.query;
    } else if (typeof url.query === 'object') {
      search = stringifyParams(url.query);
    }
  }

  if (search && !search.startsWith('?')) {
    search = `?${search}`;
  }

  return `${url.pathname ?? ''}${search ?? ''}${
    url.hash ? `#${url.hash}` : ''
  }`;
}

export function isCareAppDestination(url: Url): boolean {
  const normalized = normalizeUrl(url);

  const path = new URL(normalized, 'http://localhost').pathname;

  // Request paths that should redirect to the care app
  const paths = [
    /^\/log-in/i,
    /^\/sign-up/i,
    /^\/reset-password/i,
    /^\/update-password/i,
    /^\/invite/i,
    /^\/verify/i,
    /^\/account/i,
    /^\/billing/i,
    /^\/messages/i,
    /^\/faq/i,
    /^\/contact(\/.*)?$/i,
    /^\/topics/i,
    /^\/appointments(\/.*)?$/i,
    /^\/assessments(\/.*)?$/i,
    /^\/check-in(\/.*)?$/i,
    /^\/patient-assessments(\/.*)?$/i,
    /^\/feedback(\/.*)?/i,
    /^\/rebook(\/.*)?/i,
    /^\/insurance(\/.*)?$/i,
    // /^\/providers(\/.*)?$/i, // TODO: determine long term where provider profiles should live
    /^\/providers(\/.*)\/book$/i,
    /^\/therapists/i,
    /^\/search(\/.*)?$/i,
    /^\/referral(\/.*)?$/i,
    /^\/refer(\/.*)?$/i,
    /^\/matches(\/.*)?$/i,
    /^\/coverage(\/.*)?$/i,
    /^\/groups(\/.*)?$/i,
    /^\/match(\/.*)?$/i,
    /^\/provider-patient-feedback-survey(\/.*)?$/i,
    /^\/onboard$/i,
    /^\/benefits(\/.*)?$/i,
    /^\/auth-error/i,
    /^\/auth\/login/i,
  ];

  return paths.some((p) => p.test(path));
}

export function isRequestForSameOrigin(
  request: string | Request,
  target: string
): boolean {
  if (!target) {
    return false;
  }

  if (typeof request !== 'string') {
    const targetUrl = new URL(target);

    /**
     * If the request is a Request object, we need to reconstruct the URL
     * using the hostname and port from the request object. We use the protocol
     * from the target URL because Express does not report https requests as such
     * without additional configuration. Since we use http in localhost and https
     * in production we can't hardcode the protocol.
     */
    request = `${targetUrl.protocol}//${request.hostname}${
      targetUrl.port ? `:${request.socket.localPort}` : ''
    }${request.url}`;
  }

  return new URL(request).origin === new URL(target).origin;
}

export function getFullURLForPath(path: string) {
  const originalUrl = new URL(path, process.env.NEXT_PUBLIC_MARKETING_URL);

  if (isCareAppDestination(originalUrl)) {
    return new URL(path, process.env.NEXT_PUBLIC_CARE_URL!).href;
  }
  return originalUrl.href;
}

export function getApplicationName(url: string | Request) {
  if (!url) {
    return 'AGORA';
  }

  if (isRequestForSameOrigin(url, process.env.NEXT_PUBLIC_CARE_URL!)) {
    return 'CARE';
  } else if (
    isRequestForSameOrigin(url, process.env.NEXT_PUBLIC_MARKETING_URL!)
  ) {
    return 'MARKETING';
  }

  return 'AGORA';
}
